const HANDY_API_VERSION = 'v67'
const KM_API_VERSION = 'v67'
const CPRNT_API_VERSION = 'v67'
const COMMON_API_VERSION = 'v67'

// OES共通のAPIパスを定義
const common = {
  // Store
  GET_COMMON_SETTING: '/settings',
  POST_COMMON_SETTING: '/settings/update'
}

// ハンディ用のAPIパスを定義
const handy = {
  // Store
  GET_STORES: '/stores?isGopRequired=true',
  GET_KM_STORE: '/km_stores',

  // OrderItemMemoTemplates
  GET_ORDER_ITEM_MEMO_TEMPLATE: '/order_item_memos/templates',
  POST_MERGE_ORDER_ITEM_MEMO_TEMPLATE: '/order_item_memos/templates/merge',
  POST_DELETE_ORDER_ITEM_MEMO_TEMPLATE: '/order_item_memos/templates/delete',

  // Seats
  GET_SEATS: '/seats',

  // Items
  GET_ITEMS: '/items',
  POST_ITEMS: '/items',

  // RegisteredDevices
  GET_REGISTERED_DEVICES: '/registered_devices',
  POST_REGISTERED_DEVICES_UPDATE: '/registered_devices/update',
  POST_REGISTERED_DEVICES_DELETE: '/registered_devices/delete',

  // SeatSets
  UPDATE_SEAT_SET: '/seat_sets/update',
  POST_SEAT_SETS_MERGE: '/seat_sets/merge',
  POST_SEAT_SETS_DELETE: '/seat_sets/delete',

  // Course
  POST_CREATE_COURSES: '/courses/create',
  POST_UPDATE_COURSES: '/courses/update',
  POST_DELETE_COURSES: '/courses/delete',

  // Hodai
  POST_CREATE_HODAI: '/hodais/create',
  POST_UPDATE_HODAI: '/hodais/update',
  POST_DELETE_HODAI: '/hodais/delete',

  // DeviceCount
  GET_DEVICE_COUNT: '/device_count',
  POST_UPDATE_DEVICE_COUNT: '/device_count/create',

  // Topping
  POST_UPDATE_TOPPING_LIST: '/topping_groups/update',
  POST_DELETE_TOPPING_LIST: '/topping_groups/delete',
  POST_TOPPING_GROUPS_MERGE: '/topping_groups/merge',
  POST_ITEM_BY_TOPPING_GROUPS: '/item_by_topping_groups/replace',

  // handySetting
  GET_HANDY_SETTING: '/settings',
  POST_HANDY_SETTING: '/settings/update',

  // Otoshi
  POST_REPLACE_OTOSHI_SETTING: '/otoshis/replace'
}

// KM用のAPIパスを定義
const km = {
  // KitchenPosition
  GET_KITCHEN_POSITIONS: '/kitchen_position_settings',
  POST_DELETE_KITCHEN_POSITION:
    '/kitchen_position_settings/kitchen_positions/delete',
  POST_SAVE_KITCHEN_POSITION:
    '/kitchen_position_settings/kitchen_positions/save',
  POST_SAVE_KITCHEN_POSITION_TARGETS: '/kitchen_position_settings/targets/save',

  // targetServingTime
  GET_TARGET_SERVING_TIME: '/target_serving_times',
  GET_ESTIMATED_TARGET_SERVING_TIME: '/target_serving_times/estimated',
  POST_TARGET_SERVING_TIME: '/target_serving_times/replace',

  // kmSetting
  GET_KM_SETTING: '/settings',
  POST_KM_SETTING: '/settings/update'
}

// CPRNT用のAPIパスを定義
const cprnt = {
  // Printers
  GET_PRINTERS: '/printers',

  // PrintTargetByCategory
  POST_REPLACE_PRINT_TARGET_BY_CATEGORY: '/print_targets/category/replace',
  POST_REPLACE_PRINT_TARGET_BY_ITEM: '/print_targets/item/replace'
}

const getApiVersionPath = version => {
  return `/${version}`
}

const commonUrls = Object.entries(common)
  .map(([k, v]) => ({
    [k]: `/common${getApiVersionPath(COMMON_API_VERSION)}${v}
    `
  }))
  .reduce((l, r) => Object.assign(l, r), {})

const handyUrls = Object.entries(handy)
  .map(([k, v]) => ({
    [k]: `/handy${getApiVersionPath(HANDY_API_VERSION)}${v}`
  }))
  .reduce((l, r) => Object.assign(l, r), {})

const kmUrls = Object.entries(km)
  .map(([k, v]) => ({ [k]: `/km${getApiVersionPath(KM_API_VERSION)}${v}` }))
  .reduce((l, r) => Object.assign(l, r), {})

const cprntUrls = Object.entries(cprnt)
  .map(([k, v]) => ({
    [k]: `/cprnt${getApiVersionPath(CPRNT_API_VERSION)}${v}`
  }))
  .reduce((l, r) => Object.assign(l, r), {})

export default {
  ...commonUrls,
  ...handyUrls,
  ...kmUrls,
  ...cprntUrls
}

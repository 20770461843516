import React, { useState, useEffect } from 'react'
import {
  useFailedModal,
  useInitialize,
  useUploadConfirmModal,
  useSuccessModal
} from './hooks'
import PageTemplate from '~/src/components/templates/PageTemplate'
import styled from 'styled-components'
import { Step1GetDataCode } from './Step1GetDataCode'
import { Step2UploadDataCode } from './Step2UploadDataCode'
import { datadogLogs } from '@datadog/browser-logs'
import { Datadog } from '~/src/const/datadog'

import {
  UploadMenuConfirmModal,
  UploadStoreConfirmModal,
  SuccessModal,
  FailedModal
} from './Model'

import { useSelector } from 'react-redux'
import {
  selectApiReturnCode,
  selectIsOpenFailedModal,
  selectIsOpenSuccessModal
} from './selectors'
import { PostItemsBody } from './types'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import Const from '~/src/const'
import { ExternalLink } from '~/src/components/atoms/ExternalLink'

const { Size, Color , URL} = Const

/**
 * 商品一括登録用シートのアップロード画面
 */
export const ItemsBulkUpload: React.VFC = () => {
  const breadcrumbs = [{ title: '商品一括登録用シートのアップロード' }]
  const description = [
    '商品、カテゴリー、放題プラン、コース、トッピングを一括登録します。'
  ]

  const [inputData, setInputData] = useState<string>('')

  const [parsedData, setParsedData] = useState<PostItemsBody>()

  const handleParsedData = (inputData?: PostItemsBody) => {
    setParsedData(inputData)
  }

  const isOpenSuccessModal = useSelector(selectIsOpenSuccessModal)
  const isOpenFailedModal = useSelector(selectIsOpenFailedModal)
  const errorCode = useSelector(selectApiReturnCode)

  const { onCloseSuccessModal } = useSuccessModal()
  const { onCloseFailedModal } = useFailedModal()
  const { initializeState } = useInitialize()

  const {
    isOpenUploadMenuConfirmModal,
    onOkHandler,
    onOpenHandler,
    onCloseHandler
  } = useUploadConfirmModal(parsedData)

  useEffect(() => {
    datadogLogs.logger.info(
      Datadog.BROWSER_LOG_MESSAGE.BULK_UPLOAD_STORE_CONFIRM_DIALOG.MESSAGE,
      {
        event:
          Datadog.BROWSER_LOG_MESSAGE.BULK_UPLOAD_STORE_CONFIRM_DIALOG.EVENT
      }
    )
    return () => {
      initializeState()
    }
  }, [])
  const modal = (
    <div>
      <DismissPrompt
        // 入力データがある場合のみDismissPrompt(DismissModal)を使用する
        enabled={inputData}
        whiteList={[]}
        isForRegister
      />
      <SuccessModal isOpen={isOpenSuccessModal} onClose={onCloseSuccessModal} />
      <FailedModal
        isOpen={isOpenFailedModal}
        onClose={onCloseFailedModal}
        errorCode={errorCode}
      />
      <UploadStoreConfirmModal />
      <UploadMenuConfirmModal
        isOpen={isOpenUploadMenuConfirmModal}
        onCloseHandler={onCloseHandler}
        onOkHandler={() => onOkHandler().then(() => setInputData(''))}
      />
    </div>
  )

  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      description={description}
      modal={modal}
    >
      <CautionaryStatement>
        詳しくは
        <ExternalLink url={URL.ITEMS_BULK_UPLOAD_FAQ}>
          商品一括設定用シート利用の流れ
        </ExternalLink>
        をご覧ください。
      </CautionaryStatement>
      <UserStepsContainer>
        <Step1GetDataCode />
        <Step2UploadDataCode
          inputData={inputData}
          setInputData={setInputData}
          showUploadMenuConfirmModal={onOpenHandler}
          handleParsedData={handleParsedData}
          parsedData={parsedData}
        />
      </UserStepsContainer>
    </PageTemplate>
  )
}

const CautionaryStatement = styled.div`
  color: ${Color.VERY_DARK_GRAY};
  font-size: ${Size.FONT.BASE}px;
  padding-bottom: 24px;
`

const UserStepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`
